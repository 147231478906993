<template>
  <div>
    <profile-header text="Purchases" />

    <v-data-table 
      :headers="headers"
      :items="items"
    >
      <template v-slot:[`item.invoice`]="">
        <v-icon>fas fa-download</v-icon>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import ProfileHeader from './ProfileHeader.vue'

export default {
  components: { ProfileHeader },
  data() {
    return { items: [] }
  },
  mounted() {
    const length = Math.floor(Math.random() * 20);

    for (let i = 0; i < length; i++) {
      this.items.push({
        show: "SHOW NAME",
        pod: `#${Math.floor(Math.random() * 100000000000)}`,
        total: `$${Math.floor(Math.random() * 2000)}`,
        invoice: "#"
      });
    }
  },
  computed: {
    headers() {
      return [
        { text: "SHOW", value: "show", align: "center" },
        { text: "POD ID#", value: "pod", align: "center" },
        { text: "TOTAL", value: "total", align: "center" },
        { text: "INVOICE", value: "invoice", align: "center" }
      ]
    }
  }
}
</script>